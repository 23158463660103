import "./nav.css";

function Nav() {
  return (
    <div className="nav-container">
      <h1>Sorting</h1>
    </div>
  );
}

export default Nav;
